import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, Link } from 'react-router-dom';
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/img/logo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="/" smooth={true}>
            <img src={LogoIcon} alt="company logo" height={50} width={100} />
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="/" spy={true} smooth={true} offset={-80}>
                Home
              </NavLink>
            </li>
            {/* <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                Contact
              </NavLink>
            </li> */}
            <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="/about" spy={true} smooth={true} offset={-80}>
                About US
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="/brands" spy={true} smooth={true} offset={-80}>
                Brands
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="/whyus" spy={true} smooth={true} offset={-80}>
                Why US
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="/contact" spy={true} smooth={true} offset={-80}>
                Contact
              </NavLink>
            </li>
            <li className="semiBold font15 pointer">
              <NavLink activeClass="active" style={{ padding: "10px 15px" }} to="https://www.amazon.com/s?me=A26S1VJEZ1PHG7&marketplaceID=ATVPDKIKX0DER" spy={true} smooth={true} offset={-80}>
                Our Store
              </NavLink>
            </li>
          </UlWrapper>
          {/* <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <a href="/" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="/" className="radius8 lightBg" style={{ padding: "10px 15px" }}>
                Get Started
              </a>
            </li>
          </UlWrapperRight> */}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


